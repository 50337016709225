<template>
  <div class="min-h-full relative max-h-full flex flex-col text-base-content">
    <sub-header
      class="mb-4"
      :backButton="true"
      :customCrumbLists="customBreadCrumbs"
    />
    <div class="flex-1 relative bg-card-bg rounded-md shadow">
      <div
        v-if="true"
        class="px-4 lg:px-5 py-3 flex gap-3 items-center bg-primary-300 border-b border-gray-200 text-white-text text-lg font-medium h-10"
      >
        <span class="font-bold">{{ statusDetail.name || "Loading..." }} </span>
      </div>
      <div v-if="!configLoader" class="flex w-full flex-col p-4 justify-center">
        <div>
          <create-status
            workflowType="globalCase"
            :mode="mode"
            :status="statusDetail"
          />
        </div>
        <div class="flex w-full justify-center items-center">
          <div class="relative bg-card-bg rounded-md shadow flex-1">
            <div
              v-if="true"
              class="px-4 lg:px-5 py-1 flex gap-3 items-center border-b border-gray-200 text-base-content-600 text-sm font-medium rounded-t-lg bg-gray-200"
            >
              Status can be updated to any of the following.
            </div>
            <div class="flex w-full flex-col p-3 justify-center">
              <div class="flex justify-center items-center gap-10">
                <div
                  class="flex border-2 items-center justify-center p-4 rounded-lg"
                >
                  <StatusBadge
                    :label="statusDetail.name"
                    :color="statusDetail.colour"
                    customClass="rounded-full"
                  />
                </div>
                <div class="flex p-3">
                  <svg
                    width="47"
                    height="10"
                    viewBox="0 0 47 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M46.2067 5.70664C46.5974 5.31602 46.5974 4.68164 46.2067 4.29102L42.2067 0.291016C41.8161 -0.0996094 41.1817 -0.0996094 40.7911 0.291016C40.4005 0.681641 40.4005 1.31602 40.7911 1.70664L43.0849 4.00039L1.50049 4.00039C0.947363 4.00039 0.500488 4.44727 0.500488 5.00039C0.500488 5.55352 0.947363 6.00039 1.50049 6.00039L43.0849 6.00039L40.7911 8.29414C40.4005 8.68476 40.4005 9.31914 40.7911 9.70977C41.1817 10.1004 41.8161 10.1004 42.2067 9.70977L46.2067 5.70977V5.70664Z"
                      fill="#0D69D5"
                    />
                  </svg>
                </div>
                <div class="flex flex-col overflow-y-auto scroll-bar">
                  <ul
                    v-for="(status, index) in statusList"
                    :key="index"
                    class="flex justify-between items-center gap-10 py-2"
                  >
                    <div class="flex justify-between items-center gap-10">
                      <input
                        type="checkbox"
                        class="w-5 h-5 cursor-pointer"
                        :class="{ 'cursor-not-allowed': disabledList }"
                        :value="status.id"
                        v-model="checkedStatusList"
                        :disabled="disabledList"
                        @change="handleListChecked(status.id)"
                      />
                      <StatusBadge
                        :label="status.name"
                        :color="status.colour"
                        customClass="rounded-full"
                      />
                    </div>
                    
                    <button
                      type="button"
                      :disabled="getPermissionsButtonDisabled(status.id)"
                      class="inline-flex items-center gap-2 ml-8 py-0.5 px-4 rounded border"
                      :class="{
                        'bg-primary-50 text-primary cursor-pointer hover:bg-primary-100': getPermissionsButtonEnabled(status.id),
                        'bg-gray-300 text-gray-500 pointer-events-none': getPermissionsButtonDisabled(status.id)
                      }"
                      @click="showGroupsModal(status.id)"
                    >
                      <span>Permissions</span>
                      <font-awesome-icon
                        icon="chevron-down"
                        class="text-main text-sm"
                        :class="{
                          'text-primary': getPermissionsButtonEnabled(status.id),
                          'text-gray-500': getPermissionsButtonDisabled(status.id)
                        }"
                      />
                    </button>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex justify-end pt-4">
          <div class="">
            <v-button
              text="Save"
              class="py-2"
              type="primary"
              :loader="statusUpdateLoading"
              @click="updateStatusData"
              :disabled="buttonsDisabled"
            />
          </div>
        </div>
      </div>

      <div v-else class="flex w-full flex-col p-4 justify-center items-center">
        <v-loader />
      </div>
    </div>

    <GroupsModal
      ref="status-permissions-modal"
      :groups="groups"
      :value="value"
      @input="setSelectedGroups"
      @cancel="onGroupsModalCancel"
      @onSubmit="onGroupsModalSubmit"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubHeader from "@/components/SubHeader";
import CreateStatus from "@shared/workflow/components/create-status";
import StatusBadge from "@shared/workflow/components/status-badge";
import Loader from "@shared/components/loader";
import GroupsModal from "../GroupsModal";

import {
  fetchStatusDetails,
  fetchTenantGroups,
  fetchClientGroups,
  updateStatusTransition,
} from "@shared/workflow/service.js";
import Button from "@shared/components/button";

export default {
  name: "statusConfiguration",

  components: {
    CreateStatus,
    StatusBadge,
    SubHeader,
    GroupsModal,
    "v-button": Button,
    "v-loader": Loader,
  },

  data() {
    return {
      checkedStatusList: [],
      mode: "edit",
      statusDetail: {},
      configLoader: false,
      statusUpdateLoading: false,
      selectedStatusId: null,
      groups: {
        tenant: [],
        client: []
      },
      groupsOriginData:  [],
      statusPerGroupsMap: {},
    };
  },

  computed: {
    ...mapGetters(["getTenantId"]),

    statusList() {
      const allStatusList = this.$store.getters.getWorkflowStateTransitionList;
      return allStatusList.filter(
        (el) => el.id !== this.getStatusId && el.is_active === true
      );
    },
    getStatusId() {
      return this.$route.params.statusId;
    },
    getWorkflowId() {
      return this.$route.params.workflowId;
    },
    getType() {
      return this.$route.params.type;
    },
    getNameValidationError() {
      return this.formData.hasNameError;
    },
    getDescriptionValidationError() {
      return this.formData.hasDescriptionError;
    },
    getColorValidationError() {
      return !this.formData.hasColorError;
    },
    formData() {
      return this.$store.getters.getEditStatusFormData;
    },
    customBreadCrumbs() {
      return [
        { name: this.getType },
        { name: "Workflow" },
        { name: "State Transition" },
        { name: "Configure Workflow" },
      ];
    },
    disabledList() {
      return !this.statusDetail?.is_active;
    },
    buttonsDisabled() {
      return this.getNameValidationError
      || this.getColorValidationError
      || this.getDescriptionValidationError
      || this.statusUpdateLoading;
    },
    defaultGroupIds() {
      return [
        ...this.groups.tenant.map(el => el?.id),
        ...this.groups.client.map(el => el?.groups?.map(g => g?.group_id)).flat(),
      ]
    },
    value() {
      return this.statusPerGroupsMap[this.selectedStatusId] ?? [];
    },
    selectedStatusHasPermissions() {
      return !!Object.keys(this.statusPerGroupsMap[this.selectedStatusId]).length
    },
  },

  async mounted() {
    try {
      this.configLoader = true;
      const response = await fetchStatusDetails(this.getType, this.getStatusId);
      if (response.status) {
        this.statusDetail = response?.data?.data;
      }
      await this.fetchTenantGroupsList();
      await this.fetchClientGroupsList();
      await this.initModel();
    } catch (err) {
      console.log("err", err);
      this.$toast.error(err?.response?.data?.status_message || "Something went wrong");
    } finally {
      this.configLoader = false;
    }
  },

  methods: {
    handleListChecked(statusId) {
      this.workFlowEnd = "";
      if (statusId in this.statusPerGroupsMap) {
        return this.$delete(this.statusPerGroupsMap, statusId);
      }
      this.$set(this.statusPerGroupsMap, statusId, [...this.defaultGroupIds]);
    },

    initModel() {
      if (!this.statusDetail.to_status_ids.length) return;

      this.checkedStatusList = this.statusDetail.to_status_ids?.map(el => el?.status_id) ?? [];

      for (const statusId of this.checkedStatusList) {
        const allowedGroups = this.statusDetail.to_status_ids
          .find(el => el.status_id === statusId).allowed_groups;
        const groupsToSet = allowedGroups.length ? [...allowedGroups] : [...this.defaultGroupIds];
        this.$set(this.statusPerGroupsMap, statusId, groupsToSet);
      }
    },

    setSelectedGroups(event) {
      const currentModel = this.statusPerGroupsMap[this.selectedStatusId];
      if (Array.isArray(event)) {
        if (event.length > 0) {
          this.statusPerGroupsMap[this.selectedStatusId] = Array.from(
            new Set([ ...currentModel, ...event ])
          );
        } else {
          this.statusPerGroupsMap[this.selectedStatusId] = currentModel
            .filter(groupId => !this.groups.tenant.find(tg => tg.id === groupId))
        }

        return;
      }

      // the case when we want to force a particular value:
      if (typeof event === "object") {
        const { items, value } = event;
        if (value) {
          this.statusPerGroupsMap[this.selectedStatusId] = Array.from(
            new Set([ ...currentModel, ...items ])
          );
          return;
        } else {
          this.statusPerGroupsMap[this.selectedStatusId] = currentModel
            .filter(groupId => !items.some((id) => id === groupId));
        }
      }

      if (currentModel.includes(event)) {
        this.statusPerGroupsMap[this.selectedStatusId] = currentModel.filter(groupId => groupId !== event);
      } else {
       currentModel.push(event);
      }
    },

    async updateStatusData() {
      this.statusUpdateLoading = true;
      try {
        const payload = {
          workflow_id: this.getWorkflowId,
          name: this.formData.statusName?.toString().trim(),
          colour: this.formData.statusColor.id,
          is_active: this.formData.activeStatus,
          from_status_id: this.getStatusId,
          to_status_ids: this.getToStatusIdsPayload(),
        };

        if (this.formData.statusDescription) {
          payload.description = this.formData.statusDescription
            ?.toString()
            .trim();
        }

        const response = await updateStatusTransition(this.getType, payload);
        if (response.status) {
          this.$toast.success(response?.data?.success_message || "Success");
          this.$router.back();
        }

        this.statusUpdateLoading = false;
      } catch (err) {
        this.statusUpdateLoading = false;
        console.log("err", err);
        this.$toast.error(err?.response?.data?.status_message || "Something went wrong");
      }
    },

    getToStatusIdsPayload() {
      return this.checkedStatusList.map(statusId => ({
        status_id: statusId,
        allowed_groups: this.statusPerGroupsMap[statusId],
      }));
    },

    async fetchTenantGroupsList() {
      try {
        this.groups.tenant = await fetchTenantGroups(this.getTenantId);
      } catch(err) {
        console.log("err", err);
      }
    },

    async fetchClientGroupsList() {
      try {
        this.groups.client = await fetchClientGroups();
      } catch(err) {
        console.log("err", err);
      }
    },

    showGroupsModal(statusId) {
      this.selectedStatusId = statusId;
      if (!this.statusPerGroupsMap[this.selectedStatusId].length) {
        this.statusPerGroupsMap[this.selectedStatusId] = [...this.defaultGroupIds];
      }

      this.groupsOriginData = [...this.statusPerGroupsMap[this.selectedStatusId]];
      this.$refs["status-permissions-modal"]?.$refs["status-permissions-content"]?.showModal();
    },

    onGroupsModalCancel() {
      this.statusPerGroupsMap[this.selectedStatusId] = this.groupsOriginData;
      this.groupsOriginData = [];
      this.onGroupsModalClose();
    },

    onGroupsModalSubmit() {
      if (!this.selectedStatusHasPermissions) {
        this.$toast.error("Can't save state transition permission. Please provide at least one group the permission.");
        return;
      }
      this.onGroupsModalClose();
    },

    onGroupsModalClose() {
      this.selectedStatusId = null;
      this.$refs["status-permissions-modal"]?.$refs["status-permissions-content"]?.hideModal();
    },

    getPermissionsButtonDisabled(statusId) {
      return !this.checkedStatusList.includes(statusId) || this.buttonsDisabled;
    },

    getPermissionsButtonEnabled(statusId) {
      return this.checkedStatusList.includes(statusId) && !this.buttonsDisabled;
    }
  },
};
</script>
